@use 'styles/variables/typography' as *;
@use 'styles/variables/index' as *;

.button {
    padding: 5px 10px;
    transition: transform $short__duration;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.buttonArrow {
    max-width: none !important;
}

.children {
    overflow: hidden;
    transition: max-height $short__duration, margin $short__duration;
    max-height: 0;
    margin: 0;
}

.active {
    .button {
        transform: rotate(-90deg);
    }

    &.dropdownHalfRotation .button {
        transform: rotate(-180deg);
    }

    .children {
        max-height: 200px;
        overflow-y: auto;
        margin: 10px 0;
    }
}