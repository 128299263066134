@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/typography' as *;

@mixin button(
    $background: $color__accent,
    $color: $color__secondary-alt,
    $font-weight: $font-weight__medium,
    $font-size: $font-size__small,
    $padding: 10px 30px,
    $border-radius: 20px,
    $transition-duration: $short__duration,
    $hover-background: $color__secondary-alt,
    $hover-color: $color__accent
) {
    background: $background;
    color: $color;
    font-weight: $font-weight;
    font-size: $font-size;
    padding: $padding;
    border-radius: $border-radius;
    transition: color $transition-duration, background-color $transition-duration;

    &:hover {
        background: $hover-background;
        color: $hover-color;
    }
}
