@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;
@use 'styles/mixins' as *;

.title {
    font-size: $font-size__l;
    font-weight: $font-weight__medium;
    margin-bottom: 15px;
}

.description {
    margin-bottom: 15px;
}

.buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 15px;
}

.button {
    border-width: 2px;
    border-style: solid;
    width: calc(25% - 15px);
    min-width: 150px;

    &--secondary {
        @include button($font-size: $font-size__regular,
            $background: $color__primary-alt,
            $color: $color__secondary,
            $hover-background: $color__secondary,
            $hover-color: $color__primary-alt,
            $border-radius: 50px,
            $padding: 5px,
            $font-weight: $font-weight__semibold );
        border-color: $color__secondary;

        &--active {
            background: $color__secondary;
            color: $color__primary-alt;
        }
    }

    &--accent {
        @include button($background: $color__primary-alt,
            $font-size: $font-size__regular,
            $color: $color__secondary-alt,
            $hover-background: $color__secondary-alt,
            $hover-color: $color__accent,
            $border-radius: 50px,
            $padding: 5px,
            $font-weight: $font-weight__semibold );
        border-color: $color__accent;

        &--active {
            background: $color__secondary-alt;
            color: $color__accent;
        }
    }

    &--primary {
        @include button($background: $color__primary-alt,
            $font-size: $font-size__regular,
            $color: $color__primary,
            $hover-background: $color__primary,
            $hover-color: $color__primary-alt,
            $border-radius: 50px,
            $padding: 5px);
        border-color: $color__primary;

        &--active {
            background: $color__primary;
            color: $color__primary-alt;
        }
    }
}

@media only screen and (min-width: $screen__m) {
    .button {
        min-width: 175px;
    }
}

.tabs {
    margin-top: 25px;
}

.tab {
    margin-top: 40px;
    opacity: 0;
    position: absolute;
    transition: opacity $short__duration;

    &--active {
        opacity: 1;
        position: relative;
    }
}

.image {
    width: 300px;
    height: 250px;
    box-sizing: content-box;
    transform: rotate(-5deg);
    padding: 10px;
    object-fit: contain;
    margin: 0 auto;
}

@media only screen and (min-width: $screen__m) {
    .image {
        margin: unset;
        width: 240px;
        height: 240px;
        padding: 10px;
    }
}

.content {
    margin-left: 20px;
    width: 90%;
}

@media only screen and (min-width: $screen__m) {
    .content {
        width: 60%;
    }
}

.contentContainer {
    gap: 40px;
}

@media only screen and (min-width: $screen__m) {
    .contentContainer {
        display: flex;
        gap: 20px;
        flex-direction: row;
    }
}

.paragraph {
    font-size: $font-size__regular;
    display: flex;
    margin-right: 5px;
}

ul {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.listClass {
    font-size: $font-size__regular;
    list-style: disc;
}

.tabTitle {
    font-size: $font-size__l;
    font-weight: $font-weight__medium;
    margin-bottom: 15px;

    &--secondary {
        color: $color__secondary;
    }

    &--primary {
        color: $color__primary;
    }
}

.section,
.tiles {
    display: flex;
}

@media only screen and (min-width: $screen__m) {
    .texting {
        display: flex;
    }
}


.section {
    align-items: flex-start;

    &+& {
        margin-top: 30px;
    }
}

.tiles {
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px 15px;
}

.tile {
    padding: 10px 15px;
    height: 60px;
    border-radius: 50px;
    width: calc(25% - 15px);
    min-width: 175px;
    text-align: center;
    font-weight: $font-weight__bold;
    font-size: $font-size__regular;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background $short__duration, color $short__duration;

    &--secondary {
        background: $color__secondary;
        height: 44px !important;

        &--active,
        &:hover {
            background: $color__secondary-alt;
            color: $color__secondary;

        }
    }

    &--accent {
        background: $color__accent;

        &--active,
        &:hover {
            background: $color__secondary-alt;
            color: $color__accent;
        }
    }

    &--primary {
        background: $color__primary;

        &--active,
        &:hover {
            background: $color__primary-alt;
            color: $color__primary;
        }
    }

    &--empty {
        display: none;
        background: none;
    }
}

@media only screen and (min-width: $screen__m) {
    .tile {
        &--empty {
            display: block;
        }
    }
}

@media only screen and (min-width: $screen__l) {
    .tile {
        &--empty {
            display: none;
        }
    }
}

@media only screen and (min-width: $screen__xl) {
    .tile {
        &--empty {
            display: block;
        }
    }
}

.swiperContainer {
    position: relative;
}

.swiperPagination {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
}


.dotContainer {
    width: 45px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: $screen__s) {
    .dotContainer {
        width: 25px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

.dots {
    left: 0;
    right: 0;
    bottom: 25px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}


.dot {
    width: 20px;
    height: 20px;
    border: 2px solid $color__secondary-alt;
    border-radius: 50%;
    cursor: pointer;
}

@media only screen and (min-width: $screen__s) {
    .dot {
        width: 15px;
        height: 15px;
        cursor: pointer;
    }
}

.dotActive {
    background: $color__secondary-alt;
    cursor: default;
    cursor: pointer;
}

.bulletContent{
    display: flex;
    margin-top: 5px;
    flex-direction: column;
}

