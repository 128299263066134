@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;
@use 'styles/mixins' as *;

.link {
    display: inline-block;
    font-size: $font-size__regular !important;

    @include button(
            $background: $color__accent,
            $color: $color__secondary-alt,
            $hover-background: $color__secondary-alt,
            $hover-color: $color__primary-alt
    );
}

.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

.calContainer {
    min-width: 38vh;
    height: 90vh;
    margin-bottom: auto;
  }

@media only screen and (min-width: $screen__l) {
    .calContainer {
        min-width: 1000px;
        height: 684px;
        margin-bottom: 30px;
    }
}