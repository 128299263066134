@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;

.card {
    padding: 25px 25px 75px 25px;
    position: relative;
}

@media only screen and (min-width: $screen__s) {
    .card {
        padding: 75px;
    }
}

@media only screen and (min-width: $screen__l) {
    .card {
        padding: 125px 75px;
    }
}

.UO {
    position: absolute;
    top: 0;
    left: 0;
    padding: 25px;
    filter: invert(80%) sepia(36%) saturate(297%) hue-rotate(74deg) brightness(103%) contrast(105%);
    opacity: 0.75;
}

@media only screen and (min-width: $screen__s) {
    .UO {
        padding: 50px;
    }
}

@media only screen and (min-width: $screen__l) {
    .UO {
        width: 60%;
        height: 100%;
    }
}

.dotContainer{
    width: 45px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: $screen__s) {
    .dotContainer{
        width: 25px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
}

.dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 25px;
    display: flex;
    justify-content: center;
}


.dot {
    width: 20px;
    height: 20px;
    border: 2px solid $color__secondary-alt;
    border-radius: 50%;
    cursor: pointer;
}

@media only screen and (min-width: $screen__s) {
    .dot {
        width: 15px;
        height: 15px;
    }
}

.dotActive {
    background: $color__secondary-alt;
    cursor: default;
}
