.frontSide,
.backSide {
    padding: 20px;
}

.hiddenSide {
    display: none;
}

.frontSide.frontTestimonials {
    width: 450px;
}

.backSide {
    transform: scaleX(-1);
}