@use 'styles/variables/responsive' as *;

.container {
    padding: 0 20px;
    margin: 0 auto;
}

@media only screen and (min-width: $screen__l) {
    .container {
        max-width: $screen__l;
    }
}

@media only screen and (min-width: $screen__xl) {
    .container {
        max-width: $screen__xl;
    }
}