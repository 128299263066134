@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;

.ribbon {
    position: relative;
    height: 100px;
    overflow-x: hidden;
}
.primary,
.secondary {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    height: inherit;
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    width: 150vw;

    &.primary {
        padding-right: 20px;
    }
}

@media only screen and (min-width: $screen__m) {
    .ribbon {
        height: 140px;
    }

    .primary{
        padding-top: 60px;
    }
    .secondary {
        padding-top: 60px;
    }
}

.primary {
    animation: primary 30s linear infinite;
}

.secondary {
    animation: secondary 30s linear infinite;
}

@keyframes primary {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes secondary {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

.secondRibbon {
    position: relative;
    height: 100px;
    overflow-x: hidden;
}

.secondRibbonPrimary,
.secondRibbonSecondary {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    height: inherit;
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    padding: 0;
    width: 150vw;
}


@media only screen and (min-width: $screen__m) {
    .secondRibbon {
        height: 140px;
    }

    .secondRibbonPrimary,
    .secondRibbonSecondary {
        padding-top: 16px;
        padding-bottom: 44px;
    }
}

.secondRibbonPrimary {
    animation: secondRibbonPrimary 30s linear infinite;
  }
  
  .secondRibbonSecondary {
    animation: secondRibbonSecondary 30s linear infinite;
  }
  
  @keyframes secondRibbonPrimary {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes secondRibbonSecondary {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }


.image {
    aspect-ratio: 16/9;
    object-fit: contain;
    transition: filter $short__duration;

}
