@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;
@use 'styles/mixins' as *;

.services {
    padding: 0;
}

.titleContainerWrapper {
    padding: 60px 0;
    background: $color__secondary-alt;
    margin-bottom: 2px;
}

@media only screen and (min-width: $screen__s) {
    .titleContainerWrapper {
        padding: 60px 0;
        background: $color__secondary-alt;
        margin-bottom: 0px;
    }
}

@media only screen and (min-width: $screen__l) {
    .titleContainerWrapper {
        padding: 60px 0;
        background: $color__secondary-alt;
        margin-bottom: 0px;
    }
}

.title {
    text-align: center;
    max-width: $screen__l;
    color: $color__primary-alt;
    font-size: $font-size__l;
    margin: 0 auto;
    cursor: default;
}

@media only screen and (min-width: $screen__s) {
    .title {
        font-size: $font-size__xxl;
    }
}

@media only screen and (min-width: $screen__l) {
    .title {
        font-size: $font-size__3xl;
    }
}

.outline {
    border-width: 3px;
    border-style: solid;
    padding: 0 20px;
    border-radius: 40px;
    line-height: 1.35;
    transition: background-color $short__duration, border-color $short__duration;

    &.inactive {
        border-color: $color__primary-alt;
    }

    &--secondary {
        border-color: $color__secondary;

        &:hover,
        &.active {
            background: rgba($color__secondary, .5);
        }
    }

    &--accent {
        border-color: $color__accent;

        &:hover,
        &.active {
            background: rgba($color__accent, .5);
        }
    }

    &--primary {
        border-color: $color__primary;

        &:hover,
        &.active {
            background: rgba($color__primary, .5);
        }
    }

    &--inverted {
        border-width: 2px;
        color: $color__secondary-alt;
        border-color: $color__secondary-alt;
    }
}

.content {
    position: relative;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media only screen and (min-width: $screen__l) {
    .contentContainer {
        padding: 50px 20px;
        flex-direction: row;
    }
}

.aside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $color__primary-alt;
    position: relative;
    padding: 10px 0px;

    &::before {
        content: "";
        background: $color__secondary-alt;
        position: absolute;
        top: 0;
        right: -20px;
        width: 100vw;
        height: calc(100% + 0px);
        z-index: -1;
    }
}

@media only screen and (min-width: $screen__l) {
    .aside {
        width: 33%;
        padding: 0px 0px;
        padding-right: 10px;

        &::before {
            top: -48px;
            right: 0;
            height: calc(100% + 100px - 2px);
        }
    }
}

.asideTitle {
    font-size: $font-size__l;
    font-weight: $font-weight__medium;
    margin-bottom: 50px;
    max-width: $screen__xxs;
}

@media only screen and (min-width: $screen__xs) {
    .asideTitle {
        max-width: $screen__s;
    }
}

@media only screen and (min-width: $screen__l) {
    .asideTitle {
        max-width: unset;
    }
}

.asideControls {
    font-weight: $font-weight__bold;
    font-size: $font-size__big;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 15px 10px;

}

@media only screen and (min-width: $screen__s) {
    .asideControls {
        font-weight: $font-weight__bold;
        font-size: $font-size__big;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: flex-start;
        gap: 15px 10px;
        
    }
}

@media only screen and (max-width: $screen__s) {
    .asideDesktopControls {
      display: block;
    }
  
    .asideMobileControls {
      display: none;
    }

  }
  
  @media only screen and (min-width: $screen__s) {
    .asideDesktopControls {
      display: none;
    }
  
    .asideMobileControls {
      display: block;
    }
    
    .asideButton {
        display: block;
        line-height: 1.2;
        font-weight: bold;
        margin-bottom: 10px;
        font-size: $font-size__big;

    }
  }

.slide {
    margin: 0 10px;
  }

.asideButton {
    display: block;
    line-height: 1.2;
    font-weight: bold;

}

.sliderWrapper {
    margin-top: 20px;
}

@media only screen and (min-width: $screen__l) {
    .sliderWrapper {
        margin-top: 0;
        width: 66%;
    }
}

.sliderDots {
    position: absolute;
    bottom: -15px;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;

    li {
        position: relative;
        display: inline-block;
        padding: 0;
        cursor: pointer;
    }

}

.dot {
    display: block;
    width: 40px;
    border-top: 2px solid rgba($color__secondary-alt, .5);
    transition: border-top-color $short__duration;

    &--active {
        border-top: 2px solid $color__accent;
    }
}

.sliderDot {
    padding: 5px;
}

.slide {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

@media only screen and (min-width: $screen__s) {
    .slide {
        flex-direction: row;
    }
}

.linkWrapper {
    text-align: center;
    margin: 20px 0;
}

@media only screen and (min-width: $screen__l) {
    .linkWrapper {
        margin-top: 40px;
    }
}

.link {
    display: inline-block;
    font-size: $font-size__regular !important;
    @include button(
            $background: $color__accent,
            $color: $color__secondary-alt,
            $hover-background: $color__secondary-alt,
            $hover-color: $color__primary-alt
    );
}

.linkMargin {
    display: inline-block;
    margin-right: 10px;
    font-size: $font-size__regular !important;
    @include button(
            $background: $color__accent,
            $color: $color__secondary-alt,
            $hover-background: $color__secondary-alt,
            $hover-color: $color__primary-alt
    );
}

.swiperTitle {
    font-size: $font-size__l;
    font-weight: $font-weight__medium;
    margin-bottom: 15px;
}


