@use 'styles/variables/responsive' as *;

.mapContainer {
    width: 100%;
    aspect-ratio: 1;
    max-height: 80vh;
}

@media only screen and (min-width: $screen__s) {
    .mapContainer {
        aspect-ratio: 16/9;
    }
}

@media only screen and (min-width: $screen__m) {
    .mapContainer {
        aspect-ratio: 21/9;
    }
}
