@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;

li{
    list-style-type: none;
}

.container {
    text-align: center;
}


li ul{
    text-align: start;
    li{
        list-style-type: disc;
    }
}

.text{
    text-align: start;
    max-width: $screen__s;
    color: $color__primary-alt;
    stroke: $color__primary-alt;
    margin-top: 10px;
}

.link{
        text-align: start;
        max-width: $screen__s;
        color: $color__primary-alt;
        stroke: $color__primary-alt;
        margin-top: 10px;
        text-decoration: underline;
}

.title {
    letter-spacing: 10px;
    text-transform: uppercase;
    position: relative;
    text-align: center;
    display: inline-block;
    margin-bottom: 50px;
    padding: 0 50px;

    &::before,
    &::after {
        content: "-";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }
}

.list {
    padding: 0;
}

@media only screen and (min-width: $screen__m) {
    .list {
        padding: 0 100px;
    }
}

.faq {
    & + & {
        padding-top: 10px;
    }
}

.dropdownTitle {
    text-align: left;
    user-select: none;
    font-weight: $font-weight__medium;
    font-size: $font-size__normal;
}

.dropdown {
    background: $color-white;
    stroke: $color__primary;
    padding: 10px 10px 10px 30px;
    transition: background-color $short__duration,
    color $short__duration,
    stroke $short__duration,
    padding $short__duration,
    box-shadow $short__duration;
    border-radius: 20px;

    &:hover {
        box-shadow: 0 0 10px 0 rgba($color__secondary-alt, 0.75);
    }

    &--active {
        background: $color__primary;
        color: $color__primary-alt;
        stroke: $color__primary-alt;
        padding: 20px 10px 20px 30px;
    }
}

.content {
    text-align: left;
    max-width: $screen__s;
    position: relative;
    padding-top: 30px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 25px;
        height: 5px;
        background: $color__accent;
    }
}

.button {
    stroke-width: 4px;
}


.loadMore{
 display: inline-block;
margin-top: 25px;
background: #340ea6;
color: #edece8;
font-weight: 500;
font-size: 12px;
padding: 10px 50px;
border-radius: 10px;
transition: color 300ms, background-color 300ms;
}