@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;
@use 'styles/mixins' as *;

.careersHero {
    position: relative;
    height: 200px;
}

@media only screen and (min-width: $screen__m) {
    .careersHero {
        height: 500px;
    }
}

.backImages {
    position: absolute;
    top: 0;
    width: 150%;
    left: -25%;
}

.container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.title {
    font-size: $font-size__4xl;
    position: relative;

    &:first-child {
        left: -20%;
    }

    &:last-child {
        right: -20%;
    }
}

@media only screen and (min-width: $screen__m) {
    .title {
        &:first-child {
            left: -40%;
        }

        &:last-child {
            right: -40%;
        }
    }

}

.backImages {
    height: 100%;
    display: flex;
    justify-content: center;
}

.backImage {
    filter: brightness(0) saturate(100%) invert(87%) sepia(27%) saturate(485%) hue-rotate(65deg) brightness(104%) contrast(97%);
    height: 100%;
    min-width: 50%;
}

@media only screen and (min-width: $screen__m) {
    .backImage {
        min-width: 25%;
    }
}

@media (max-width: 600px) {
    .text {
        top: 8vw;
    }

    .title {
        font-size: 50px !important;
    }
}

@media only screen and (min-width: $screen__m) {
    .greenTriangle,
    .blackTriangle {
        width: 0;
        height: 0;
        position: absolute;
        border-style: solid;
    }

    .greenTriangle {
        border-width: 0 0 850px 100vw;
        border-color: transparent transparent $color__accent transparent;
        top: 600px;
        right: 0;
        z-index: -1;
        height: 550px;
    }

    .blackTriangle {
        border-width: 250px 0 0 100vw;
        border-color: transparent transparent transparent $color__secondary-alt;
        top: 600px;
        left: 0;
        z-index: -1;
        height: 550px;
    }
}
