@use 'styles/variables/colors' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;

.brands {
    position: relative;
}

.title {
    margin: 50px auto;
    text-align: center;
    max-width: 360px;
    font-size: $font-size__big;
}

.tiles {
    display: flex;
    margin: 0 auto;
    gap: 20px;
    flex-direction: row;
    overflow-y: scroll;
}

@media only screen and (min-width: $screen__s) {
    .tiles {
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 0;
        overflow-y: unset;

        &--no-shapes {
            justify-content: center;
        }
    }
}

@media only screen and (min-width: $screen__m) {
    .tiles {
        max-width: $screen__m;
    }
}

@media only screen and (min-width: $screen__l) {
    .title {
        margin: 20px auto 100px;
        max-width: $screen__xs;
        font-size: $font-size__l;
    }

    .tiles {
        flex-wrap: nowrap;
        max-width: unset;
    }
}

.tile {
    height: 400px !important;
    width: 20% !important;
    min-width: 200px;
    z-index: 10;

    &--white {
        color: $color__primary-alt;
    }

    &--black {
        color: $color__secondary-alt;
    }
}

.shapes .tile {
    height: 500px !important;
    width: auto;
}

@media only screen and (min-width: $screen__s) {
    .tile {
        &:nth-child(4n - 2) {
            transform: translateY(50%);
        }
    }

    .tiles--no-shapes .tile {
        width: 40% !important;
        transform: none;
    }
}

@media only screen and (min-width: $screen__l) {
    .tile {
        &:nth-child(2n) {
            transform: translateY(50%);
        }
    }

    .tiles--no-shapes .tile {
        height: 500px !important;
        width: 25% !important;
        transform: none;
        margin-left: 10px;
    }
}

.frontSideImage {
    max-width: 275px;
}

.backSide {
    text-align: center;
    width: 350px;
}

.backSideImage {
    max-width: 200px;
    margin: 0 auto 20px;
}

.author {
    font-size: $font-size__3xl;
    font-weight: $font-weight__bold;
}

.frontSideText {
    font-size: $font-size__xxl;
font-weight: $font-weight__medium;}

.quote {
    display: block;
    font-size: 260px;
    margin-top: -20px;
    margin-bottom: -140px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.subtext {
    font-size: $font-size__l;
    text-align: center;
    padding: 0 25px;
}

.shapesWrapper {
    display: none;
}

@media only screen and (min-width: $screen__l) {
    .shapesWrapper {
        display: block;
        position: relative;
        overflow: hidden;
        max-height: 450px;
        pointer-events: none;

        &::after {
            content: '';
            background-image: linear-gradient(to top, rgba($color__primary-alt, 255), transparent);
            position: absolute;
            height: 300px;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}

.shapes {
    padding: 0 15px;
}
