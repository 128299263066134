@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/colors' as *;
@use 'styles/variables/typography' as *;

.team {
    position: relative;
}

.backImage {
    position: absolute;
    z-index: -1;
    opacity: 0.1;
    left: 20%;
    top: 10%;
    width: 60vw;
    height: 600px;
}

.circleImage {
    position: absolute;
    height: 150px;
    right: -25px;
    top: -25px;
}

.head {
    position: relative;
}

.title {
    font-size: $font-size__4xl;
}

.subtitle {
    font-size: $font-size__big;
    font-weight: $font-weight__medium;
    margin-top: 10px;
    max-width: $screen__xs;
}

.swiper {
    margin-top: 50px;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
     margin-right: 30px;

    &--active {
        .image {
            filter: none;
        }

        .arrowWrap {
            display: block;
            z-index: 1;
        }

        .name, .position, .description, .linkedin {
            opacity: 1;
            display:block;
        }
    }
}

.image {
    width: 300px;
    min-height: 435px;
    height: 450px;
    object-fit: cover;
    filter: grayscale(100%);
    transition: filter $short__duration;
}

.name, .position, .description, .linkedin {
    transition: opacity $short__duration;
}

.name {
    font-size: $font-size__l;
    opacity: 0.3;
}

.position {
    font-style: italic;
    font-weight: $font-weight__semibold;
    font-size: $font-size__normal-plus;
    margin-top: 7px;
    opacity: 0.3;
    letter-spacing: 2px;
}

.description {
    margin-top: 20px;
    opacity: 0;
    display: none;
    text-align: justify;
}

.descriptionLine {
    & + & {
        margin-top: 10px;
    }
}

.linkedin {
    opacity: 0;
    display: none;
    margin-top: 20px;
}

.content {
    margin-top: 25px;
    position: relative;
    width: 100%;
    text-align: center;
}

.arrowWrap {
    display: none;
    position: absolute;
    padding: 10px;
    cursor: pointer;
    top: 50%;
}

.arrow {
    stroke: black;
    stroke-width: 4px;
}

.arrowLeft {
    left: -40px;
    transform: translateY(-50%) rotate(90deg);
}

.arrowRight {
    right: -40px;
    transform: translateY(-50%) rotate(270deg);
}

.greenBack {
    background-color: $color-led-lime;
    position: absolute;
    width: 120%;
    height: 2000px;
    right: 0;
    top: 35%;
    z-index: -1;
    -webkit-clip-path: polygon(0 13%, 100% 100%, 100% 0);
    clip-path: polygon(0 13%, 100% 100%, 100% 0);
}

@media (max-width: 600px) {
    .head {
        .title {
            font-size: $font-size__xxl;
        }

        .subtitle {
            font-size: $font-size__normal;
        }
    }
}
