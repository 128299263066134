@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;


.FollowUS {
    margin-top: 60px;
}

@media (max-width: 600px) {
    .FollowUS {
        margin-top: 0;
    }
}

.container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

@media (max-width: 1050px) {
    .container {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}

.items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    min-height: 350px;
}

@media only screen and (min-width: $screen__l) {
    .items {
        flex-direction: row;
        min-height: 400px;
    }
}

@media only screen and (min-width: $screen__m) {
    .text {
        max-width: $screen__xs;
    }
}

.title {
    font-size: $font-size__3xl;
}

.description {
    font-size: 25px;
    margin-top: 25px;
    font-weight: 400;
}

@media (max-width: 450px) {
    .title {
        font-size: 40px;
    }

    .description {
        font-size: 18px;
        margin-top: 10px;
    }
}
