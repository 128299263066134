@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;
@use 'styles/mixins' as *;

@media only screen and (min-width: $screen__l) {
    .blogSide {
        position: absolute;
        top: 0;
        right: 0;
        width: 50vw;
        overflow: hidden;
    }
}

.blogSideWrapper {
    background-color: $color__secondary-alt;
}

@media only screen and (min-width: $screen__l) {
    .blogSideWrapper {
        min-height: 200vh;
        margin-left: 10vw;
        border-bottom-left-radius: 500px;
        border-bottom-right-radius: 500px;
    }
}

.currentImage {
    width: 100%;
    height: 350px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media only screen and (min-width: $screen__l) {
    .currentImage {
        width: 150%;
        position: relative;
        left: -10vw;
        bottom: -15vw;
        overflow: hidden;
        border-radius: 180px;
    }
}

.latest {
    padding: 50px 20px 50px;

    .mainTitle {
        color: $color-off-white;
        font-size: $font-size__l;
    }
}

@media only screen and (min-width: $screen__l) {
    .latest {
        margin-top: 380px;
        padding: 30px 5vw 200px;
    }
}

@media only screen and (min-width: $screen__xxl) {
    .latest {
        margin-top: 515px;
        padding: 30px 5vw 200px;
    }
}

.latestNoHeader {
    padding: 50px 20px 50px;

    .mainTitle {
        color: $color-off-white;
        font-size: $font-size__l;
    }
}

@media only screen and (min-width: $screen__l) {
    .latestNoHeader {
        margin-top: 0px;
        padding: 30px 5vw 200px;
    }
}

@media only screen and (min-width: $screen__xxl) {
    .latestNoHeader {
        margin-top: 0px;
        padding: 30px 5vw 200px;
    }
}

.postsList {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.post {
    display: flex;
    gap: 20px;
    cursor: pointer;
    align-items: center;
    z-index: 2;
    position: relative;
}

.image {
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    border-radius: 25px;
    overflow: hidden;
    object-fit: cover;
}

.info {
    color: $color-off-white;

    h3 {
        font-weight: $font-weight__medium;
        margin-top: 10px;
    }
}

@media only screen and (min-width: $screen__l) {
    .info {
        h3 {
            width: 70%;
        }
    }
}
