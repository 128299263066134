@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;
@use 'styles/mixins' as *;

.categories {
    background: $color__secondary-alt;
}

.container {
    text-align: center;
}
.imageWrapper {
    position: relative;
}

.imageInnerWrapper {
    position: relative;
}

.checkmarkContainer {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    z-index: 1;
}

.checkmark {
    position: relative;
    color: $color__primary-alt;
}

.headline {
    color: $color__primary-alt;
    font-size: $font-size__l;
    margin: 0 auto 50px;
    max-width: $screen__xs;
    z-index: 5;
    position: relative;
}

@media only screen and (min-width: $screen__m) {
    .headline {
        font-size: $font-size__xxl;
    }
}

@media only screen and (min-width: $screen__l) {
    .headline {
        margin: 200px auto 250px;
    }
}

.subtitle {
    color: $color__primary-alt;
    font-weight: $font-weight__medium;
    margin-top: 30px;
    opacity: .75;

    &--hidden {
        opacity: 0;
    }
}

.list {
    display: flex;
    flex-wrap: wrap;
    gap: 50px 30px;
    margin-top: 125px;
    justify-content: space-evenly;
}

@media only screen and (min-width: $screen__l) {
    .list {
        gap: 75px;
        justify-content: space-between;
    }
}

.item {
    position: relative;
    transition: filter $short__duration, opacity $short__duration;
    cursor: pointer;

    &--disabled {
        filter: grayscale(1);
        opacity: .5;
    }
}

.title {
    padding: 10px 20px;
    text-transform: uppercase;
    font-weight: $font-weight__bold;
    max-width: 200px;
    width: fit-content;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border-radius: 50px;
    user-select: none;
    will-change: transform;

    &--bg {
        &-blue {
            background: $color__secondary;
        }
        &-ultraBlue {
            background: $color__primary;
            color: $color__primary-alt;
        }
        &-lime {
            background: $color__accent;
        }
        &-white {
            background: $color__primary-alt;
        }
    }
}

@media only screen and (min-width: $screen__l) {
    .title {
        height: 60px;
        padding: 10px 40px;
    }
}

.imageWrapper {
    width: 50px;
    height: 50px;
    position: absolute;
    top: -75%;
    right: -25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    will-change: transform;

    &--bg {
        &-blue {
            background: $color__secondary;
        }
        &-ultraBlue {
            background: $color__primary;
            color: $color__primary-alt;
        }
        &-lime {
            background: $color__accent;
        }
        &-white {
            background: $color__primary-alt;
        }
        &-black {
            background: $color__secondary-alt;
        }
    }
}

@media only screen and (min-width: $screen__l) {
    .imageWrapper {
        width: 75px;
        height: 75px;
    }
}

.image {
    &--inverted {
        filter: invert(1);
    }
}
