@use 'styles/variables/colors' as *;
@use 'styles/variables/responsive' as *;

.cardWrapper {
    position: absolute;
    will-change: transform;
    touch-action: none;
    border-radius: 20px;
    background: $color__primary-alt;
    box-shadow: 0 10px 100px -10px rgba($color__secondary-alt, 0.4);
    margin: 0 20px;
    height: 680px;
    display: flex;
}

@media only screen and (min-width: $screen__s) {
    .cardWrapper {
        margin: 0 50px;
        height: 710px;
    }
}

@media only screen and (min-width: $screen__l) {
    .cardWrapper {
        margin: 0;
        height: 600px;
    }
    
}

.cardWrapperMarket {
    position: absolute;
    will-change: transform;
    touch-action: none;
    border-radius: 20px;
    background: $color__primary-alt;
    box-shadow: 0 10px 100px -10px rgba($color__secondary-alt, 0.4);
    margin: 0 20px;
    height: 760px;
    display: flex;
}

@media only screen and (min-width: $screen__s) {
    .cardWrapperMarket {
        margin: 0 50px;
        height: 836px;
    }
}

@media only screen and (min-width: $screen__l) {
    .cardWrapperMarket {
        margin: 0;
        height: 600px;
    }
    
}