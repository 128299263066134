@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;
@use 'styles/mixins' as *;
@use 'styles/variables/colors' as *;

.container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    align-items: flex-start;
}

@media only screen and (min-width: $screen__m) {
    .container {
        flex-direction: row;
    }
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.image {
    border-radius: 25px;
}

@media only screen and (min-width: $screen__m) {
    .popupContent {
        line-height: 1.4;
        background-color: #fff;
        margin: 0 auto;
        border-radius: 5px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 1200px;
        border-radius: 20px;
        padding: 40px;
    }

    .popupTitle {
        font-size: $font-size__big;
        margin-bottom: 6px;
    }

    .popupSubTitle {
        font-size: $font-size__medium;
        margin-bottom: 6px;
        font-weight: $font-weight__semibold;
    }

    .body {
        font-size: $font-size__normal;
    }

    .popupList {
        list-style-type: disc;
        font-size: $font-size__normal;
    }

    .fieldLabel {
        font-size: $font-size__normal;
    }

    .field input,
    .field textarea {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 5px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        font-size: 16px;
        resize: none;
    }

    .button {
        width: 34%;
        margin: 6px 0px;
        @include button($background: $color__secondary-alt,
            $color: $color-off-white,
            $hover-background: $color__secondary-alt,
            $hover-color: $color-led-lime,
            $padding: 10px 40px,
            $font-size: $font-size__medium,
        );
    }
    
    .cta--dark {
        width: 34%;
        margin-bottom: 6px;
        .button {
            @include button($background: $color-led-lime,
                $color: $color__secondary-alt,
                $hover-background: $color__primary-alt,
                $hover-color: $color__secondary-alt,
                $padding: 10px 40px,
                $font-size: $font-size__medium,
            );
        }
    }

    .formContent {
        margin-top: 6px;
    }
}

@media only screen and (max-width: $screen__m) {
    .popupContent {
        line-height: 1.4;
        background-color: #fff;
        margin: 0 auto;
        padding: 20px;
        border-radius: 5px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 86%;
        max-width: 1200px;
        border-radius: 20px;
        max-height: 84vh;
        overflow-y: auto;
    }    

    .popupTitle {
        font-size: $font-size__medium;
        margin-bottom: 6px;
    }

    .popupSubTitle {
        font-size: $font-size__normal;
        margin-bottom: 6px;
        font-weight: $font-weight__semibold;
    }

    .body {
        font-size: $font-size__regular;
    }

    .popupList {
        list-style-type: disc;
        font-size: $font-size__regular;
    }

    .fieldLabel {
        font-size: $font-size__regular;
    }

    .field input,
    .field textarea {
        width: 100%;
        padding: 6px;
        border: none;
        border-radius: 5px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        font-size: 14px;
        resize: none;
    }

    .button {
        margin: 5px 0px 14px;
        @include button($background: $color__secondary-alt,
            $color: $color-off-white,
            $hover-background: $color__secondary-alt,
            $hover-color: $color-led-lime,
            $padding: 10px 30px,
            $font-size: $font-size__normal,
        );
    }
    
    .cta--dark {
        margin: 5px 0px 14px;
        .button {
            @include button($background: $color-led-lime,
                $color: $color__secondary-alt,
                $hover-background: $color__primary-alt,
                $hover-color: $color__secondary-alt,
                $padding: 10px 30px,
                $font-size: $font-size__normal,
            );
        }
    }

    .formContent {
        margin-top: 10px;
    }
}

.imageContainer {
    z-index: 1;
    width: 100%;
    margin-bottom: 10px;
}

@media only screen and (min-width: $screen__m) {
    .imageContainer {
        z-index: 1;
        width: 74%;
        padding: 10px;
        margin-bottom: 0px;
        display: flex;
    }

    .image {
        border-radius: 25px;
        width: 80%;
        margin-left: 18%;
    }
}




.bodyContainer {
    z-index: 1;
}

.link {
    display: inline-block;
    margin-top: 6px;
    cursor: pointer;
    @include button($background: $color__secondary-alt,
        $color: $color__accent,
        $hover-background: $color__accent,
        $hover-color: $color__secondary-alt,
        $padding: 15px 20px,
        $font-size: $font-size__normal );
}

@media only screen and (min-width: $screen__l) {
    .link {
        margin-top: 0px;
    }
}



.field {
    width: 100%;
    margin-bottom: 6px;
    padding-right: 20px;

}

input::placeholder,
textarea::placeholder {
    color: lightgray;
}

.field label {
    display: block;
    margin-bottom: 6px;
}


.required {
    color: red;
}

.error {
    margin-top: 6px;
    color: red;
}

form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.formSection {
    background-color: #edece8;

    color: #000;
}

.field__half {
    width: 100%;
    padding-right: 20px;
    box-sizing: border-box;
}

.field__half:last-child {
    padding-right: 0;
}

.formSection--dark {
    background-color: #000;
    color: #fff;
}

@media screen and (max-width: 768px) {
    .field__half {
        width: 100%;
        padding-right: 0;
    }
}
.textPadding{
    padding-bottom: 6px;
}