@use 'styles/variables/colors' as *;

.mapboxgl-control-container {
    display: none;
}

.mapboxgl-marker {
    svg {
        path {
            fill: $color__accent
        }

        circle {
            fill: $color__secondary-alt;
        }
    }
}
