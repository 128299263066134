@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;
@use 'styles/mixins' as *;

.section{
    background: linear-gradient(165deg, transparent 45%, #b1fbb6 42%);

}

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    padding-bottom: 60px;
}

@media only screen and (min-width: $screen__l) {
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 60px;
        padding-bottom: 120px;
    }
    
}


hr{
    margin: 0 12%;
}

@media only screen and (min-width: $screen__l) {
    hr{
        margin: 0 30%;
    }
}

.title {
    font-size: $font-size__xl;
    margin-bottom: 20px;
    text-align: center;
}

.form {
    background: $color__secondary-alt;
    color: $color__accent;
    border-radius: 10px;
    padding: 20px;
    gap: 20px;
    margin-top: 30px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.label {
    text-align: center;
    display: block;
    margin-bottom: 15px;
    font-size: $font-size__medium-plus;
    font-weight: $font-weight__medium;
}

.actions {
    position: relative;
    background: $color__secondary-alt;
    width: 100%;
}

@media only screen and (min-width: $screen__s) {
    .actions {
        position: relative;
        background: $color__secondary-alt;
        width: 100%;
        display: flex;
        gap: 20px;
        padding: 0 20px;
    }
}

.input {
    background: $color__primary-alt;
    padding: 5px 10px;
    font-size: $font-size__medium;
    font-weight: $font-weight__medium;
    border-radius: 5px;
    height: 40px;
    width: 100%;
    color: $color__secondary-alt;
}

.submit {
    text-transform: uppercase;
    margin: 10px auto 0;
    display: block;

    @include button(
            $padding: 5px 50px,
            $border-radius: 5px,
            $font-size: $font-size__big
    );
}

@media only screen and (min-width: $screen__s) {
    .submit {
        margin: unset;

    }
}

.content {
    width: 100%;
}

@media only screen and (min-width: $screen__l) {
    .content {
        width: 75%;
    }
}