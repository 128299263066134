@use 'styles/variables/index' as *;
@use 'styles/variables/colors' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;
@use 'styles/mixins' as *;

.lab {
    position: relative;
}

@media only screen and (min-width: $screen__l) {
    .blackTriangle {
        border-style: solid;
        border-width: 350px 50vw 50vw;
        border-color: $color__secondary-alt transparent transparent transparent;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -10;
    }
}

.container {
    position: relative;
}

.cards {
    height: 680px;
    z-index: 10;
    position: relative;
}

@media only screen and (min-width: $screen__xs) {
    .cards {
        height: 700px;
    }
}

@media only screen and (min-width: $screen__s) {
    .cards {
        height: 750px;
    }
}

@media only screen and (min-width: $screen__l) {
    .cards {
        height: 550px;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: relative;
}

@media only screen and (min-width: $screen__l) {
    .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@media only screen and (min-width: $screen__xl) {
    .content {
        gap: 100px;
    }
}

.desc {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    user-select: none;
}

@media only screen and (min-width: $screen__l) {
    .desc,
    .video {
        max-width: 50%;
    }
}

.title {
    color: $color__secondary-alt;
    font-size: $font-size__big;
    margin-bottom: 10px;
}

@media only screen and (min-width: $screen__l) {
    .title {
        font-size: $font-size__l;
    }
}

@media only screen and (min-width: $screen__xl) {
    .title {
        font-size: $font-size__l;
    }
}


.subtitle {
    font-size: $font-size__normal;
    margin-bottom: 50px;
}

@media only screen and (min-width: $screen__l) {
    .subtitle {
        font-size: $font-size__medium;
    }
}

@media only screen and (min-width: $screen__xl) {
    .subtitle {
        font-size: $font-size__medium;
    }
}

.linkWrapper {
    text-align: center;
    gap: 20px;
}

@media only screen and (min-width: $screen__l) {
    .linkWrapper {
        text-align: left;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }
}

.link {
    display: inline-block;
    margin-top: 10px;

    @include button(
            $background: $color__secondary-alt,
            $color: $color__accent,
            $hover-background: $color__accent,
            $hover-color: $color__secondary-alt,
            $padding: 15px 30px,
            $font-size: $font-size__normal
    );
}

@media only screen and (min-width: $screen__l) {
    .link {
        margin-top: 0px;
    }
}


.video {
    position: relative;
    display: flex;
    align-items: center;

    // &::after {
    //     content: "";
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     width: 0;
    //     height: 0;
    //     border-style: solid;
    //     border-width: 25px 0 25px 40px;
    //     border-color: transparent transparent transparent $color__primary-alt;
    //     transition: border-color $short__duration;
    // }

    .videoImage {
        border-radius: 25px;
        // filter: brightness(50%);
        // transition: filter $short__duration;
    }

    // &:hover {
    //     &::after {
    //         border-color: transparent transparent transparent $color__accent;
    //     }

    //     .videoImage {
    //         filter: brightness(25%);
    //     }
    // }
}


