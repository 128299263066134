// Index imports
@use 'reset' as *;
@use 'typography' as *;
@use 'variables/typography' as *;
@use 'variables/colors' as *;

// Slick library styles
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

body {
    font-family: $font-family__base;
    background: $color__primary-alt;
    position: relative;
}

#app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
// align to left
.grecaptcha-badge {
  z-index: 99999;
    width: 70px !important;
    height: 47px !important;

    overflow: hidden !important;
    transition: all 0.3s ease !important;
    left: 4px !important;
  }
  
  .grecaptcha-badge:hover,  .grecaptcha-badge:active  {
    width: 256px !important;
    height: 60px !important;
  }
 
  @media only screen and (max-width: 480px) {
    .grecaptcha-badge {
      display: none;
    }
}