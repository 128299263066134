@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;
@use 'styles/mixins' as *;

.footer {
    background: $color__secondary-alt;
}

.strip {
    height: 70px;
    width: 100vw;
    margin-bottom: 20px;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        height: 100vw;
        width: 70px;
        top: 50%;
        left: 50%;
        background: url('/assets/homepage/strip-white.webp') 0 0 repeat;
        transform: translate(-50%, -50%) rotate(90deg);
        background-size: contain;
    }
}

@media only screen and (min-width: $screen__m) {
    .strip {
        height: 150px;
        margin-bottom: 30px;

        &::before {
            width: 150px;
        }
    }

    .container {
        display: flex;
    }
}

.navigation {
    padding-top: 20px;
}

.logo {
    width: 200px;
    fill: $color__primary-alt;
}

.navigationLinks {
    margin-top: 30px;
    margin-bottom: 20px;
    padding-left: 0;
}


.linksItem {
    color: $color__primary-alt;
    font-weight: $font-weight__regular;
    font-size: $font-size__extrabig;
    transition: color $short__duration;
    margin-bottom: 10px;
    list-style: none;

    & + & {
        margin-top: 10px;
    }

    &:hover {
        color: $color__accent;
        stroke: $color__accent;
        font-weight: $font-weight__medium;
    }
}

.link {
    display: block;

    &, &:active, &:visited {
        color: inherit;
    }
}

.contactUsLink{
    display: none;

}
@media only screen and (min-width: $screen__m) {
    .contactUsLink{
        display: block;

        &, &:active, &:visited {
            color: inherit;
        }
    }
}

.linkActive {
    &, &:active, &:visited {
        color: $color__accent;
        font-weight: $font-weight__medium;
    }
}

@media only screen and (min-width: $screen__m) {
    .border {
        width: 2px;
        background: $color__primary-alt;
        min-height: 100%;
        margin: 0 50px;
    }
}

@media only screen and (min-width: $screen__l) {
    .border {
        margin: 0 150px;
    }
}

.content {
    padding-top: 20px;
    margin-bottom: 20px;
}

@media only screen and (min-width: $screen__m) {
    .content {
        margin-bottom: 0;
        width: 60%;
    }
}

.contact {
    margin-bottom: 20px !important;
    width: 100%;
}

@media only screen and (min-width: $screen__m) {
    .contact {
        margin-bottom: 100px;
        display: flex;
        justify-content: space-between;
    margin-bottom: 0px !important;
    }
}

.contactUs {
    margin-bottom: 50px;
}

.subscribe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 28px;
}

@media only screen and (min-width: $screen__m) {
    .subscribe {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-direction: row;

    }
}


.socialLink {
    max-height: 50px;
}

.socialIcon {
    filter: invert(1);
    will-change: filter;
}

.socialIcons {
    display: flex;
    gap: 10px;
}

.button {
    text-transform: uppercase;

    @include button(
            $padding: 15px 50px,
            $border-radius: 50px,
            $font-size: $font-size__medium,
            $font-weight: $font-weight__bold
    );
}

.form {
    position: relative;
}

@media only screen and (min-width: $screen__m) {
    .form {
        width: 400px;
    }
}

.title {
    color: $color__primary-alt;
    font-size: $font-size__extrabig;
    font-weight: $font-weight__bold;
    margin-bottom: 20px;
    max-width: $screen__xxs;
}

.input {
    background: $color__primary-alt;
    padding: 5px 15px;
    font-weight: $font-weight__medium;
    border-radius: 20px;
    height: 40px;
    width: 100%;
    color: $color__secondary-alt;
    margin-bottom: 10px;
}

@media only screen and (min-width: $screen__s) {
    .input {
        padding: 5px 65px 5px 15px;
        width: 400px;
        margin-bottom: 0;
    }
}

.submit {
    text-transform: uppercase;
    height: 100%;
    width: 150px;
    display: block;
    margin: 0 auto;

    @include button(
            $padding: 5px 20px,
            $border-radius: 20px,
            $font-size: $font-size__medium,
            $font-weight: $font-weight__bold
    );
}

@media only screen and (min-width: $screen__s) {
    .submit {
        position: absolute;
        right: -1px;
        display: inline;
        margin-left: auto;
    }
}

.bottom {
    border-top: 1px solid $color__primary-alt;
    padding: 20px 5px;
    color: $color__primary-alt;
    text-align: center;
    font-weight: $font-weight__medium;
    font-size: $font-size__regular;
}



.info {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    gap: 0;
    color: $color-off-white;
    max-width: min(82%, $screen__xs);
}

@media only screen and (min-width: $screen__m) {
    .info {
        gap: 20px;
    }
}


.icon {
    margin-bottom: 10px;
    filter: brightness(0) saturate(100%) invert(87%) sepia(27%) saturate(485%) hue-rotate(65deg) brightness(104%) contrast(97%);
}

.emailTitle {
    font-size: $font-size__l;
    text-transform: uppercase;
}

.emailLink {
    cursor: pointer;
    color: $color__primary-alt;
    transition: color $short__duration;

    &:hover {
        color: $color__accent;
    }
}