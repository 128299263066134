@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;
@use 'styles/mixins' as *;


  .title{
    color: black
  }
  
  .container{
    border-radius: 30px;
  }

  .button {
    @include button($background: $color__secondary-alt !important, 
        $color: $color-off-white !important,
        $hover-background: $color__secondary-alt !important,
        $hover-color: $color-led-lime !important,
        $padding: 10px 50px,
        $font-size: $font-size__big !important,
    );
    margin-top: 50px;
    border-radius: 50px!important;
    outline: none !important;
    box-shadow: none !important;
}
.button:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;

  }