@use 'styles/variables/colors' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (min-width: $screen__m) {
    .container {
        flex-direction: row;
    }
}

.title {
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: $font-size__big;
    white-space: nowrap;
}

.videoWrapper {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: 100%;
    margin: 50px 0;
    background-image: url('/assets/UO.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

@media only screen and (min-width: $screen__m) {
    .videoWrapper {
        max-width: 50%;
        margin: 0;
    }
}

.video {
    mix-blend-mode: lighten;
    background: $color__primary-alt;
}

.titleContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.upImage{
    width: 28px;
}

.loImage{
    width: 16px;
}