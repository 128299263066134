@use 'styles/variables/colors' as *;
@use 'styles/variables/typography' as *;

.whyWork {
    background-color: $color__secondary-alt;
}

.title {
    color: $color__primary-alt;
    font-size: $font-size__xl;
}
.whiteText{
    color: $color__primary-alt;
    line-height:    1.2;
    padding: 40px 0px 60px 0px;

}