@use 'styles/variables/colors' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;
@use 'styles/mixins' as *;

.lab {
    position: relative;
}

@media only screen and (min-width: $screen__l) {
    .blackRectangle {
        position: absolute;
        background: $color__secondary-alt;
        width: 150vw;
        height: 700px;
        transform: rotate(20deg);
        top: -50%;
        left: -25vw;
        z-index: -11;
    }
}

.container {
    position: relative;
}

.cards {
    height: 750px;
    z-index: 10;
    position: relative;
}

@media only screen and (min-width: $screen__xs) {
    .cards {
        height: 700px;
    }
}

@media only screen and (min-width: $screen__s) {
    .cards {
        height: 860px;
    }
}

@media only screen and (min-width: $screen__l) {
    .cards {
        height: 550px;
    }
}

@media only screen and (min-width: $screen__l) {
    .card {
        padding: 75px;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    height: 85%;
    position: relative;
    justify-content: space-between;
}

@media only screen and (min-width: $screen__l) {
    .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@media only screen and (min-width: $screen__xl) {
    .content {
        gap: 100px;
    }
}

.desc {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    user-select: none;
}

@media only screen and (min-width: $screen__l) {
    .desc,
    .video {
        max-width: 50%;
    }
}

.title {
    color: $color__secondary-alt;
    font-size: $font-size__big;
    margin-bottom: 10px;
}

@media only screen and (min-width: $screen__l) {
    .title {
        font-size: $font-size__l;
    }
}

@media only screen and (min-width: $screen__xl) {
    .title {
        font-size: $font-size__l;
    }
}


.subtitle {
    font-size: $font-size__normal;
    margin-bottom: 50px;
}

@media only screen and (min-width: $screen__l) {
    .subtitle {
        font-size: $font-size__medium;
    }
}

@media only screen and (min-width: $screen__xl) {
    .subtitle {
        font-size: $font-size__medium;
    }
}

.linkWrapper {
    text-align: center;
}

@media only screen and (min-width: $screen__l) {
    .linkWrapper {
        text-align: left;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 20px;
    }
}

.link {
    display: inline-block;
    margin-top: 10px;

    @include button(
            $background: $color__secondary-alt,
            $color: $color__accent,
            $hover-background: $color__accent,
            $hover-color: $color__secondary-alt,
            $padding: 15px 50px,
            $font-size: $font-size__normal
    );
}

@media only screen and (min-width: $screen__l) {
    .link {
        margin-top: 0px;
    }
}

.imageWrapper {
    position: relative;
}

.image {
    border-radius: 50%;
    max-width: 100%;
}

@media only screen and (min-width: $screen__s) {
    .image {
        max-width: 90%;
    }
}

@media only screen and (min-width: $screen__l) {
    .image {
        max-width: 400px;
    }
    
}

.circle {
    position: absolute;
    top: 0;
    right: 0;
    background: $color__primary-alt;
    padding: 15px;
    border-radius: 50%;
    box-sizing: content-box;
}
