@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;
@use 'styles/mixins' as *;

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.persons {
    display: flex;
}

.person {
    padding: 8px;
    background: $color__primary-alt;
    border-radius: 50%;
    border: 2px solid $color__secondary-alt;

    &+& {
        margin-left: -30px;
    }
}

@media only screen and (min-width: $screen__m) {
    .person {
        padding: 18px;

        &+& {
            margin-left: -40px;
        }
    }
}

.personText {
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size__l;
    font-weight: $font-weight__bold;
    color: $color__primary-alt;
    background: $color__secondary-alt;
}

@media only screen and (min-width: $screen__m) {
    .personText {
        font-size: $font-size__xl;
        width: 120px;
    }
}

.text {
    text-align: center;
    margin-top: 35px;
}

.title {
    font-size: $font-size__xl;
}

.description {
    font-size: $font-size__normal-plus;
    margin-top: 10px;
}

.button {
    @include button($background: $color-led-lime,
        $color: $color__secondary-alt,
        $hover-background: $color__secondary-alt,
        $hover-color: $color-led-lime,
        $padding: 10px 50px,
        $font-size: $font-size__medium,
    );
    margin-top: 40px;
    width: 322px;

    &--dark {
        @include button($background: $color__secondary-alt,
            $color: $color-off-white,
            $padding: 10px 50px,
            $font-size: $font-size__medium,
        );
    }
}

.sendButton {
    @include button($background: $color-led-lime,
        $color: $color__secondary-alt,
        $hover-background: $color__secondary-alt,
        $hover-color: $color-led-lime,
        $padding: 10px 50px,
        $font-size: $font-size__medium,
    );
    margin-top: 40px;

    &--dark {
        @include button($background: $color__secondary-alt,
            $color: $color-off-white,
            $padding: 10px 50px,
            $font-size: $font-size__medium,
        );

    }
}

.cvButton {
    @include button(
        $background: $color-off-white,
        $color: $color__secondary-alt,
        $hover-background: $color__secondary-alt,
        $hover-color: $color-led-lime,
        $padding: 10px 50px,
        $font-size: $font-size__medium,
    );
    margin-bottom:10px;
    &--dark {
        border: 3px solid #000;
        @include button
        (
            $background: $color-off-white,
            $padding: 10px 50px,
            $font-size: $font-size__medium,
        $hover-background: $color__secondary-alt,

        );

    }
}

@media only screen and (min-width: $screen__m) {
    .title {
        font-size: $font-size__3xl;
    }

    .description {
        font-size: $font-size__big;
    }
}

.cvContainer{
    display: flex;
    align-items: center;
}

@media only screen and (min-width: $screen__m) {
    .cvContainer{
        display: flex;
        align-items: center;
        width: 50%;
        padding-right: 10px;
    }
    
}


input::placeholder,
textarea::placeholder {
    color: lightgray;
}

.field label {
    display: block;
    margin-bottom: 10px;
}

.field input,
.field textarea {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    resize: none;
}

.required {
    color: red;
}

.error {
    margin-top: 6px;
    color: red;
}

form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.formSection {
    background-color: #edece8;

    color: #000;
}

.cvField{
    width: 100% !important;
    padding-right: 0px !important;
    box-sizing: border-box;
    border-radius: 5px 0px 0px 5px !important;
}

@media only screen and (min-width: $screen__m) {
    .cvField{
        padding-right: 0px !important;
        box-sizing: border-box;
        border-radius: 5px 0px 0px 5px !important;
    }
    
}

.field {
    width: 100%;
    margin-bottom: 20px;
    padding-right: 0px;

}

@media only screen and (min-width: $screen__m) {
    .field {
        width: 100%;
        margin-bottom: 20px;
        padding-right: 20px;
    
    }
    
}

.closeButton{
    cursor: pointer;
    background-color: white;
    margin: 0px;
    height: 38px;
    padding: 10px;
    border-radius: 0px 5px 5px 0px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    &:hover{
        .closeIcon{
            opacity: 50%;
        }
    }
}

.closeIcon{
    color: red;
}

.field__half {
    width: 50% ;
    padding-right: 20px;
    box-sizing: border-box;
}

.field__half:last-child {
    padding-right: 0;
}

.formSection--dark {
    background-color: #000;
    color: #fff;
}

@media screen and (max-width: 768px) {
    .field__half {
        width: 100%;
        padding-right: 0;
    }
}

.buttonComtainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

@media only screen and (min-width: $screen__s) {
    .buttonComtainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 10px;
    }
}