@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;

.contactInfo {
    background-color: $color__secondary-alt;
}

.container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

@media only screen and (min-width: $screen__m) {
    .container {
        justify-content: space-evenly;
    }
}

.info {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    color: $color-off-white;
    max-width: min(82%, $screen__xs);
}

.icon {
    margin-bottom: 10px;
    filter: brightness(0) saturate(100%) invert(87%) sepia(27%) saturate(485%) hue-rotate(65deg) brightness(104%) contrast(97%);
}

.description {
    font-size: $font-size__medium;
    text-align: center;
}

.title {
    font-size: $font-size__l;
    text-transform: uppercase;
}

.link {
    cursor: pointer;
    color: $color__primary-alt;
    transition: color $short__duration;

    &:hover {
        color: $color__accent;
    }
}

.border {
    display: none;
}

@media only screen and (min-width: $screen__m) {
    .border {
        display: block;
        height: 190px;
        width: 2px;
        background-color: $color__primary-alt;
    }
}
