@use 'styles/variables/colors' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;

.kpi {
    background: $color__accent;
}

.container {
    padding: 30px 20px;
}

.items {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

@media only screen and (min-width: $screen__m) {
    .items {
        gap: 50px;
        justify-content: space-between;
    }
}

.item {
    width: calc(50% - 10px);
}

@media only screen and (min-width: $screen__m) {
    .item {
        width: auto;
    }
}

.image {
    margin: 0 auto 10px;
}

.title {
    font-size: $font-size__xl;
    margin-bottom: 10px;
}

@media only screen and (min-width: $screen__m) {
    .title {
        font-size: $font-size__xxl;
    }
}

.description {
    font-size: $font-size__medium;
    width: auto
}

.header {
    font-size: $font-size__l;
    margin-bottom: 40px;
    text-align: center;
}

@media only screen and (min-width: $screen__m) {
    .header {
        font-size: $font-size__xl;
        margin-bottom: 60px;
        text-align: center;
    }
    .description {
        font-size: $font-size__medium;
        width: 240px
    }
}

.kpiDark {
    background: $color__secondary-alt;

    .items {
        color: $color__primary-alt;
    }

    .image {
        filter: invert(80%) sepia(36%) saturate(297%) hue-rotate(74deg) brightness(103%) contrast(105%);
    }

    .header {
        color: $color__primary-alt;
    }
    
}
