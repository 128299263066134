@use 'styles/variables/colors' as *;
@use 'styles/variables/responsive' as *;

.kpi {
    position: relative;
    padding: 100px 0;
}

@media only screen and (min-width: $screen__s) {
    .kpi {
        overflow: hidden;
        padding: 300px 0;
    }
}

.greenTriangle,
.blackTriangle {
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
}

.greenTriangle {
    border-width: 400px 250vw 400px 0;
    border-color: transparent $color__accent transparent transparent;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: -1;
}

.blackTriangle {
    border-width: 400px 0 400px 250vw;
    border-color: transparent transparent transparent $color__secondary-alt;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: -1;
}

@media only screen and (min-width: $screen__l) {
    .greenTriangle {
        border-width: 400px 150vw 400px 0;
    }

    .blackTriangle {
        border-width: 400px 0 400px 150vw;
    }
}

.sectionKPI {
    padding: 0;
}
