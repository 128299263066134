@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;
@use 'styles/mixins' as *;

.cta {
    position: relative;
    background-color: $color-led-lime;
    -webkit-clip-path: polygon(0 17%, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 17%, 100% 0, 100% 100%, 0% 100%);
    padding-top: 150px;
    overflow: hidden;

    &--dark {
        color: $color-off-white;
        background: $color__secondary-alt;
        clip-path: none;
    }
}

@media only screen and (min-width: $screen__m) {
    .cta {
        -webkit-clip-path: polygon(0 35%, 100% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0 35%, 100% 0, 100% 100%, 0% 100%);

        &--dark {
            clip-path: none;
        }
    }
}

@media only screen and (min-width: $screen__s) {
    .cta {
        padding-top: 290px;
    }
}

@media only screen and (max-width: $screen__m) {
    .cta {
        padding-top: 150px;
    }
}

.field {
    width: 100%;
    margin-bottom: 20px;
    padding-right: 20px;

}

input::placeholder,
textarea::placeholder {
  color: lightgray;
}

.field label {
    display: block;
    margin-bottom: 10px;
}

.field input,
.field textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  resize: none;
}

.required {
    color: red;
  }

  .error {
    margin-top: 6px;
    color: red;
  }
  
form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.formSection {
    background-color: #edece8;

    color: #000;
}

.field__half {
    width: 50%;
    padding-right: 20px;
    box-sizing: border-box;
}

.field__half:last-child {
    padding-right: 0;
}

.formSection--dark {
    background-color: #000;
    color: #fff;
}

@media screen and (max-width: 768px) {
    .field__half {
        width: 100%;
        padding-right: 0;
    }
}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.whiteCircle {
    position: absolute;
    width: 60vw;
    height: 60vw;
    right: -15vw;
    top: 5%;
    border-radius: 50%;
    background-color: $color-off-white;
    z-index: -1;
}

@media only screen and (min-width: $screen__m) {
    .whiteCircle {
        width: 70vw;
        height: 70vw;
        top: 18%;
    }
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text {
    margin-top: 60px;
}

.title {
    font-size: $font-size__xl;
    margin-right: 50px;
}

.description {
    max-width: $screen__xs;
    font-size: $font-size__normal-plus;
    margin-top: 20px;
}

@media only screen and (min-width: $screen__m) {
    .title {
        font-size: $font-size__3xl;
        margin-right: 0;
    }

    .description {
        font-size: $font-size__extrabig;
    }
}

.button {
    @include button($background: $color__secondary-alt,
        $color: $color-off-white,
        $hover-background: $color__secondary-alt,
        $hover-color: $color-led-lime,
        $padding: 10px 50px,
        $font-size: $font-size__big,
    );
    margin-top: 50px;
}

.cta--dark {
    .button {
        @include button($background: $color-led-lime,
            $color: $color__secondary-alt,
            $hover-background: $color__primary-alt,
            $hover-color: $color__secondary-alt,
            $padding: 10px 50px,
            $font-size: $font-size__big,
        );
    }
}

.image {
    position: absolute;
    right: 0;
    top: 30%;
    transform: translateY(-50%);
}

@media only screen and (min-width: $screen__xs) {
    .image {
        right: 0;
        top: 50%;
    }
}

.cta--dark {
    .image {
        filter: invert(80%) sepia(36%) saturate(297%) hue-rotate(74deg) brightness(103%) contrast(105%);
    }
}

@media only screen and (min-width: $screen__m) {
    .image {
        position: static;
        transform: none;
    }
}