@use 'styles/variables/responsive' as *;

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.icon {
    width: 75px;
    height: 100px;
}

@media only screen and (min-width: $screen__m) {
    .icon {
        width: 150px;
        height: 200px;
    }
}
