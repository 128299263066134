@use 'styles/variables/colors' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;

.testimonials {
    position: relative;
}

.title {
    text-align: center;
    max-width: 360px;
    font-size: $font-size__big;
    margin: 0 auto;
}

.tiles {
    display: flex;
    margin: 50px auto 0;
    gap: 20px;
    flex-direction: row;
    overflow-y: scroll;
}

@media only screen and (min-width: $screen__s) {
    .tiles {
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 0;
        overflow-y: unset;
    }
}

@media only screen and (min-width: $screen__m) {
    .tiles {
        max-width: $screen__m;
    }
}

@media only screen and (min-width: $screen__l) {
    .title {
        max-width: $screen__s;
        font-size: $font-size__l;
    }

    .tiles {
        margin: 100px auto 0;
        flex-wrap: nowrap;
        max-width: unset;
    }
}

.tile {
    height: 400px !important;
    width: 20% !important;
    min-width: 200px;
    z-index: 10;

    &--white {
        color: $color__primary-alt;
    }

    &--black {
        color: $color__secondary-alt;
    }
}

@media only screen and (min-width: $screen__s) {
    .tile {
        &:nth-child(4n - 2) {
            transform: translateY(50%);
        }
    }
}

@media only screen and (min-width: $screen__l) {
    .tile {
        &:nth-child(2n) {
            transform: translateY(50%);
        }
    }
}

.frontContent {
    width: 410px;
    height: 810px;
    border-radius: 200px;
    overflow: hidden;
    position: relative;
}

.frontSideImage {
    height: 100%;
    object-fit: cover;
    position: relative;
}

.text {
    position: absolute;
    z-index: 12;
    color: white;
    bottom: 20%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h3 {
        font-size: 30px;
    }
}

.overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    background-image: linear-gradient(to bottom, #ffffff00, #000000);
}

.backSide {
    text-align: center;
    width: 350px;
}

.author {
    font-size: $font-size__big;
}

.quote {
    display: block;
    font-size: 240px;
    margin-top: -40px;
    margin-bottom: -170px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.subtext {
    font-size: $font-size__l;
    text-align: center;
    padding: 0 25px;
}

.shapesWrapper {
    display: none;
}

@media only screen and (min-width: $screen__l) {
    .shapesWrapper {
        display: block;
        position: relative;
        overflow: hidden;
        max-height: 450px;
        pointer-events: none;

        &::after {
            content: '';
            background-image: linear-gradient(to top, rgba($color__primary-alt, 255), transparent);
            position: absolute;
            height: 300px;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}

.shapes {
    padding: 0 15px;
}
