// Base directory path
$baseDir: '../';

// Import typography vars
@use 'variables/typography' as *;

@font-face {
    font-family: $font-name__helvetica-neue;
    src: url("#{$baseDir}#{$font-path__helvetica-neue}-Thin.ttf") format("truetype");
    font-weight: $font-weight__thin;
    font-style: $font-style__base;
}

@font-face {
    font-family: $font-name__helvetica-neue;
    src: url("#{$baseDir}#{$font-path__helvetica-neue}-Light.ttf") format("truetype");
    font-weight: $font-weight__light;
    font-style: $font-style__base;
}

@font-face {
    font-family: $font-name__helvetica-neue;
    src: url("#{$baseDir}#{$font-path__helvetica-neue}.ttf") format("truetype");
    font-weight: $font-weight__regular;
    font-style: $font-style__base;
}

@font-face {
    font-family: $font-name__helvetica-neue;
    src: url("#{$baseDir}#{$font-path__helvetica-neue}-Medium.ttf") format("truetype");
    font-weight: $font-weight__medium;
    font-style: $font-style__base;
}

@font-face {
    font-family: $font-name__helvetica-neue;
    src: url("#{$baseDir}#{$font-path__helvetica-neue}-Bold.ttf") format("truetype");
    font-weight: $font-weight__bold;
    font-style: $font-style__base;
}

@font-face {
    font-family: $font-name__helvetica-neue;
    src: url("#{$baseDir}#{$font-path__helvetica-neue}-Heavy.ttf") format("truetype");
    font-weight: $font-weight__heavy;
    font-style: $font-style__base;
}

// Italic font
@font-face {
    font-family: $font-name__helvetica-neue;
    src: url("#{$baseDir}#{$font-path__helvetica-neue}-Italic.ttf") format("truetype");
    font-weight: $font-weight__regular;
    font-style: $font-style__emphasis;
}