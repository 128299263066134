@use 'styles/variables/colors' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;
@use 'styles/mixins' as *;

.banner {
    position: relative;
}

@media only screen and (min-width: $screen__l) {

    .greenTriangle,
    .blackTriangle {
        width: 0;
        height: 0;
        position: absolute;
        border-style: solid;
    }

    .greenTriangle {
        border-width: 0 0 750px 100vw;
        border-color: transparent transparent $color__accent transparent;
        top: 0;
        right: 0;
        z-index: -1;
    }

    .blackTriangle {
        border-width: 250px 0 0 100vw;
        border-color: transparent transparent transparent $color__secondary-alt;
        bottom: 0;
        left: 0;
    }
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

@media only screen and (min-width: $screen__m) {
    .container {
        flex-direction: row;

        &--indent {
            padding-bottom: 150px;
        }
    }
}

@media only screen and (min-width: $screen__l) {
    .container {
        &--indent {
            padding-bottom: 200px;
        }
    }
}

.content {
    padding-bottom: 20px;
}

@media only screen and (min-width: $screen__m) {
    .content {
        max-width: 50%;
        padding: 50px 0;
    }
}

.image {
    max-width: 40%;
    max-height: unset;
    object-fit: contain;
    height: auto;

}

@media only screen and (max-width: $screen__m) {
    .image {
        transform: none !important;
        max-width: 100%;
        width: 100% !important;

    }
}

@media only screen and (min-width: $screen__m) {
    .image {
        position: absolute;
        right: 20px;
        height: calc(100% + 140px);
    }
}

.title {
    font-size: $font-size__xl;
    line-height: 1;
}

.subtext {
    margin-top: 15px;
}

.button {
    margin-top: 45px;

    @include button($background: $color__secondary-alt,
        $color: $color__accent,
        $hover-background: $color__accent,
        $hover-color: $color__secondary-alt,
        $font-size: $font-size__regular,
        );
}