@use 'styles/variables/colors' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;

.card {
    background-color: $color-off-white;
    border-radius: 30px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 350px;
    justify-content: center;

    &.shadow {
        box-shadow: 9px -1px 50px -48px $color__secondary-alt;
    }
}

.title {
    margin-top: 30px;
    letter-spacing: 3px;
    color: $color__secondary-alt;
    font-size: $font-size__l;
    text-transform: uppercase;
}

.description {
    margin-top: 8px;
    width: 70%;
    color: $color__secondary-alt;
    font-size: $font-size__medium;
}

@media only screen and (min-width: $screen__m) {
    .card {
        padding: 75px 20px;
    }
}
