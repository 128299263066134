@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;
@use 'styles/mixins' as *;

.email {
    padding: 0;
    background: $color__accent;
    position: relative;
}

@media only screen and (min-width: $screen__l) {
    .greenTriangle {
        width: 0;
        height: 0;
        position: absolute;
        border-style: solid;
        border-width: 0 0 670px 100vw;
        border-color: transparent transparent $color__accent transparent;
        top: 0;
        left: 0;
        z-index: -10;
        transform: translateY(-100%);
    }
}

.emailWrapper {
    padding: 20px 0;
}

@media only screen and (min-width: $screen__l) {
    .emailWrapper {
        padding: 60px 0;
    }
}

@media only screen and (min-width: $screen__l) {
    .emailWrapper {
        padding: 60px 0 100px 0;
        position: relative;
        overflow: hidden;

        &::before {
            position: absolute;
            content: "";
            width: 75vw;
            aspect-ratio: 1 / 1;
            border: 15vw solid $color__primary-alt;
            border-radius: 50%;
            overflow: hidden;
            margin: 0;
            right: -15%;
            top: 0;
            max-width: $screen__xl;
            max-height: $screen__xl;
        }
    }
}

@media only screen and (min-width: $screen__xl) {
    .emailWrapper {
        &::before {
            width: 60vw;
            border: min(10vw, 200px) solid $color__primary-alt;
        }
    }
}

.container {
    display: flex;
    align-items: center;
    gap: 50px;
    position: relative;
}

.newsletter {
    display: none;
    max-height: $screen__xxs;
    transform: rotate(-10deg);
}

@media only screen and (min-width: $screen__l) {
    .newsletter {
        display: block;
    }
}

.title {
    font-size: $font-size__xl;
    margin-bottom: 20px;
    max-width: $screen__xs;
}

.form {
    background: $color__secondary-alt;
    color: $color__accent;
    border-radius: 10px;
    padding: 20px;
}

.label {
    display: block;
    margin-bottom: 15px;
    font-size: $font-size__medium;
    font-weight: $font-weight__medium;
}

.actions {
    position: relative;
    background: $color__secondary-alt;
    width: 100%;
}

@media only screen and (min-width: $screen__s) {
    .actions {
        position: relative;
        background: $color__secondary-alt;
        width: 78%;
    }
}

.input {
    background: $color__primary-alt;
    padding: 5px 10px;
    font-size: $font-size__medium;
    font-weight: $font-weight__medium;
    border-radius: 5px;
    height: 40px;
    width: 100%;
    color: $color__secondary-alt;
}

@media only screen and (min-width: $screen__s) {
    .input {
        padding: 5px 25px 5px 15px;
        width: calc(100% - 190px);
    }
}

.submit {
    text-transform: uppercase;
    margin: 10px auto 0;
    display: block;

    @include button(
            $padding: 5px 50px,
            $border-radius: 5px,
            $font-size: $font-size__big
    );
}

@media only screen and (min-width: $screen__s) {
    .submit {
        margin: unset;
        position: absolute;
        right: 0;
        display: inline;
        height: 100%;
        width: 200px;
    }
}

.content {
    width: 100%;
}

@media only screen and (min-width: $screen__l) {
    .content {
        width: 75%;
    }
}