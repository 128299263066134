// Color variables
$color-white: #fff;
$color-black: #000;

$color-off-white: #edece8;
$color-ultra-blue: #340ea6;
$color-violet: #480ea6;
$color-lights-out: #000;
$color-sky-blue: #4f9cd2;
$color-led-lime: #b1fbb6;
$color-blue-dark: #121A1F;

// Color nesting
$color__primary: $color-ultra-blue;
$color__primary-alt: $color-off-white;
$color__secondary: $color-sky-blue;
$color__secondary-alt: $color-lights-out;
$color__accent: $color-led-lime;
