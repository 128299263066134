@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;
@use 'styles/mixins' as *;

.partnersClients {
    background: $color__secondary-alt;
    position: relative;

    &.light {
        background-color: transparent;
        margin-top: 50px;
    }
}

@media only screen and (min-width: $screen__l) {
    .blackTriangle {
        width: 0;
        height: 0;
        position: absolute;
        border-style: solid;
        border-width: 300px 0 0 100vw;
        border-color: transparent transparent transparent $color__secondary-alt;
        top: 0;
        left: 0;
        z-index: -1;
        transform: translateY(-99%);
    }
}

.container {
    text-align: center;
}

.controls {
    margin: 0 auto;
    background: $color__primary-alt;
    border-radius: 20px;
    max-width: $screen__xs;

    &.light {
        background-color: rgb(243, 243, 243);
    }
}

@media only screen and (min-width: $screen__m) {
    .controls {
        display: flex;
        justify-content: space-between;
    }
}

.controlsTitle {
    letter-spacing: 5px;
    text-transform: uppercase;
    font-size: $font-size__regular;
    color: rgba($color__secondary-alt, 0.5);
    padding: 10px 30px;
    cursor: pointer;
    border-radius: 20px;
    transition: background-color $short__duration, color $short__duration;

    &:hover {
        color: $color__secondary-alt;
    }
}

.controlsTitleActive {
    color: $color__secondary-alt;
    background: $color__accent;
}

.tabs {
    position: relative;
    margin-top: 50px;
    overflow: hidden;
}

@media only screen and (min-width: $screen__m) {
    .tabs {
        margin-top: 100px;
    }
}

.tab {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity $short__duration;
    opacity: 0;
}

@media only screen and (min-width: $screen__m) {
    .tab {
        display: flex;
        justify-content: space-between;
    }
}

.tabActive {
    position: relative;
    opacity: 1;
    z-index: 10;
}


@media only screen and (min-width: $screen__m) {
    .content {
        width: 40%;
    }
}

.content {
    display: flex;
    color: $color__primary-alt;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.title {
    font-size: $font-size__xl;
    margin-bottom: 10px;
}

.subtitle {
    font-size: $font-size__medium;
    margin-bottom: 50px;
}

.content.light {
    .title, .subtitle {
        color: $color__secondary-alt;
    }

    .link {
        @include button(
                $background: $color__secondary-alt,
                $color: $color__accent,
                $hover-background: $color__accent,
                $hover-color: $color__secondary-alt,
                $padding: 10px 50px
        );
    }
}

.link {
    display: inline-block;

    @include button(
            $padding: 10px 50px
    );
}

.images {
    display: flex;
    justify-content: space-around;
    gap: 5px;
    flex-wrap: wrap;
}

@media only screen and (min-width: $screen__m) {
    .images {
        width: 60%;
        justify-content: space-evenly;
    }
}

@media only screen and (min-width: $screen__l) {
    .images {
        width: 50%;
    }
}

.image {
    padding: 5px 10px;
    max-width: calc(50% - 5px);
    object-fit: contain;
    filter: brightness(0.5) grayscale(1);
    transition: filter $short__duration;
    height: 80px;
    width: 200px;
    &:hover {
        filter: brightness(1) grayscale(0);
    }
}

@media only screen and (min-width: $screen__s) {
    .image {
        max-width: calc(33% - 20px);
    }
}

.images.light {
    img {
        opacity: 0.5;
        transition: 0.3s;

        &:hover {
            opacity: 1;
        }
    }
}
