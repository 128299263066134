@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;
@use 'styles/mixins' as *;


.BlogContent {
    width: 100vw;
    padding-left: 1vw;
    margin: 0;
}

@media only screen and (min-width: $screen__l) {
    .BlogContent {
        width: 50vw;
        min-height: 1640px;
    }
}

.MainText {
    width: 90%;

    h1 {
        font-size: $font-size__xxl;
        color: $color__secondary-alt;
    }

    p {
        margin-top: 20px;
        width: 90%;
        font-size: 20px;
    }

    @media (max-width: 550px) {
        width: 100%;

        h1 {
            font-size: 35px;
        }
        p {
            width: 100%;
        }
    }

    .bottomInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        row-gap: 10px;
        flex-wrap: wrap;

        .date {
            display: flex;
            color: $color__secondary-alt;
            gap: 10px;
            margin-top: 30px;
        }

        .socials {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 10px;
            margin-top: 25px;
        }

        .cursorDefault{
            cursor: default !important;
        }
    }
}

.Paragraphs {
    margin-top: 80px;
    font-size: 18px;
    line-height: 25px;
    color: $color__secondary-alt;

    p {
        margin-bottom: 20px;
    }
    h3{
        margin-bottom: 10px;
    }
    img {
        width: 90%;
        margin: 50px auto;
        border-radius: 80px;

    }
    ol{
        margin-bottom: 20px;
    }
    li {
        list-style: disc;

        p {
            margin-top: 20px;
        }
    }

    ul{
        li{
            list-style:decimal;
            margin-bottom: 10px;
        }
    }


    @media (max-width: 550px) {
        p {
            font-size: 14px;
        }
    }
}

.text3{
    margin-bottom: 4px !important;
}

.link {
    display: inline-block;
    margin-top: 10px;
    cursor: pointer;
    @include button(
            $background: $color__secondary-alt,
            $color: $color__accent,
            $hover-background: $color__accent,
            $hover-color: $color__secondary-alt,
            $padding: 15px 30px,
            $font-size: $font-size__normal
    );
}


.accessText{
    margin-top: 30px;
}

.formContent{
    margin-top: 16px;
}

.field {
    width: 100%;
    margin-bottom: 20px;
    padding-right: 20px;

}

input::placeholder,
textarea::placeholder {
  color: lightgray;
}

.field label {
    display: block;
    margin-bottom: 10px;
}

.field input,
.field textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  resize: none;
}

.required {
    color: red;
  }

  .error {
    margin-top: 6px;
    color: red;
  }
  
form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.formSection {
    background-color: #edece8;

    color: #000;
}

.field__half {
    width: 50%;
    padding-right: 20px;
    box-sizing: border-box;
}

.field__half:last-child {
    padding-right: 0;
}

.formSection--dark {
    background-color: #000;
    color: #fff;
}

@media screen and (max-width: 768px) {
    .field__half {
        width: 100%;
        padding-right: 0;
    }
}

.button {
    @include button($background: $color__secondary-alt,
        $color: $color-off-white,
        $hover-background: $color__secondary-alt,
        $hover-color: $color-led-lime,
        $padding: 10px 50px,
        $font-size: $font-size__big,
    );
}

.cta--dark {
    .button {
        @include button($background: $color-led-lime,
            $color: $color__secondary-alt,
            $hover-background: $color__primary-alt,
            $hover-color: $color__secondary-alt,
            $padding: 10px 50px,
            $font-size: $font-size__big,
        );
    }
}

.checkboxContainer  {
    display: block;
    position: relative;
    margin-bottom: 20px;
    font-size: 16px; 
    width: 100%;
  }
  
  .checkbox{
    margin: 5px;
    cursor: pointer;
  }