@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;
@use 'styles/mixins' as *;

.blogs {
    position: relative;
}

@media only screen and (min-width: $screen__l) {
    .greenTriangle,
    .blackTriangle {
        width: 0;
        height: 0;
        position: absolute;
        border-style: solid;
        top: 0;
    }

    .blackTriangle {
        border-width: 200px 100vw 0 0;
        border-color: $color__secondary-alt transparent transparent transparent;
        left: 0;
    }

    .greenTriangle {
        border-width: 0 150vw 2000px 0;
        border-color: transparent $color__accent transparent transparent;
        right: 0;
        z-index: -1;
    }
}

.logoWrapper {
    margin-bottom: 50px;
    width: 100px;
    height: 100px;
    margin-left: auto;
    display: flex;
    align-items: center;
    background: $color__secondary-alt;
    border-radius: 50%;
    transform: rotate(20deg);
    box-shadow: 0 0 10px 0 rgba($color__secondary-alt, 0.75);
}

@media only screen and (min-width: $screen__l) {
    .logoWrapper {
        margin-top: 100px;
    }
}

.logo {
    padding: 20px;
    filter: invert(1);
}

.list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 75px 20px;
}

@media only screen and (min-width: $screen__m) {
    .item {
        max-width: calc(50% - 20px);
    }
}

@media only screen and (min-width: $screen__xl) {
    .item {
        max-width: calc(33.33% - 20px);
    }
}

.link {
    color: $color__secondary-alt;
}

.imageWrapper {
    width: 100%;
    height: 250px;
    position: relative;
    border-radius: 20px;
    box-shadow: 0 0 10px 0 rgba($color__secondary-alt, 0.75);
    margin-bottom: 20px;
    background: $color__primary-alt;
}

.image {
    object-fit: cover;
    min-height: 100%;
    width: 100%;
    border-radius: 20px;
}

.category {
    position: absolute;
    top: 25px;
    right: 0;
    background: $color__accent;
    text-transform: uppercase;
    padding: 5px 20px 5px 10px;
    border-radius: 20px 0 0 20px;
    font-weight: $font-weight__medium;
    font-size: $font-size__small;
}

.title {
    font-size: $font-size__big;
    font-weight: $font-weight__bold;
    margin-bottom: 15px;
}

.content {
    font-size: $font-size__regular;
}

.showMore {
    margin-top: 20px;
}

@media only screen and (min-width: $screen__m) {
    .showMore {
        margin-top: 100px;
    }
}

.button {
    margin: 0 auto;
    display: block;

    @include button(
            $background: $color__secondary-alt,
            $color: $color__accent,
            $hover-background: $color__accent,
            $hover-color: $color__secondary-alt,
            $font-size: $font-size__big,
            $padding: 10px 50px,
            $border-radius: 50px,
    );
}
