@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;

.header {
    height: 100px;

    &--black {
        background: $color__secondary-alt;
    }
}

@media only screen and (min-width: $screen__m) {
    .header {
        height: 135px;
    }
}

.container {
    display: flex;
    justify-content: space-between;
    height: inherit;
}

.logo,
.burger {
    display: flex;
    align-items: center;
}

.logo {
    width: 100px;
}

@media only screen and (min-width: $screen__m) {
    .logo {
        width: 150px;
    }
}

.burger {
    height: 100%;
}

.menu {
    position: relative;
    z-index: 20;
}

.navigation {
    width: 100%;
    background: $color__secondary-alt;
    color: $color__primary-alt;
    stroke: $color__primary-alt;
    display: flex;
    justify-content: space-between;
    position: fixed;
    right: 0;
    top: 0;
    padding: 25px;
    transition: transform $short__duration;
    transform: translateX(100%);
    max-height: 100%;

    &_active {
        transform: translateX(0);
    }
}

@media only screen and (min-width: $screen__m) {
    .navigation {
        width: 50%;
        border-top-left-radius: 60px;
        border-bottom-left-radius: 60px;
        padding: 50px;
        box-shadow: -10px 0px 30px -10px rgba(0, 0, 0, 0.75);
    }
}

@media only screen and (min-width: $screen__xxl) {
    .navigation {
        width: 33.33%;
    }
}

.navigationContent {
    width: calc(80%);
    overflow-y: auto;
    padding-right: 20px;
}

.close {
    padding: 15px;
    margin: -15px;
}

.closeImage {
    width: 20px;
    height: 20px;
}

.navigationLinks {
    margin: 60px 0;
    padding-left: 0;
}

@media only screen and (min-width: $screen__l) {
    .navigationLinks {
        padding-left: 20px;
    }
}

.linksItem {
    font-weight: $font-weight__medium;
    font-size: $font-size__big;
    transition: color $short__duration;
    margin-bottom: 15px;

    & + & {
        margin-top: 10px;
    }

    &:hover {
        color: $color__accent;
        stroke: $color__accent;
    }
}

.link {
    display: block;

    &, &:active, &:visited {
        color: inherit;
    }
}

.linkActive {
    &, &:active, &:visited {
        color: $color__accent;
    }
}

.linksMenu {
    font-weight: $font-weight__light;
}

.linksMenuItem {
    margin-bottom: 5px;
    color: $color__primary-alt;
    transition: color $short__duration;

    &:hover {
        color: $color__accent;
    }
}

.searchForm {
    margin-top: 20px;
    position: relative;
}

@media only screen and (min-width: $screen__l) {
    .searchForm {
        margin-left: 20px;
        margin-bottom: 20px;
    }
}

.searchInput {
    padding: 7px 35px;
    border-radius: 15px;
    transition: outline-color $short__duration;
    width: 100%;
    position: relative;
    font-weight: $font-weight__medium;

    &:focus {
        outline: 2px solid $color__accent;
    }
}

.searchImage {
    width: 17px;
    height: 17px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.strip {
    width: 95px;
    margin: -25px -25px -25px 0;
    min-height: 100%;
    background: url('/assets/homepage/strip-white.webp') 0 0 repeat;
    background-size: contain;
}

@media only screen and (min-width: $screen__m) {
    .strip {
        margin: -50px -50px -50px 0;
    }
}

.iconBlack {
    fill: $color__secondary-alt;
}

.iconWhite {
    fill: $color__primary-alt;
}

.mobileIconBlack {
    fill: $color__secondary-alt;
}

@media only screen and (min-width: $screen__l) {
    .mobileIconBlack {
        fill: $color__primary-alt;
    }
}
