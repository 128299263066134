// Names
$font-name__helvetica-neue: HelveticaNeue;

// Font families
$font-family__helvetica-neue: $font-name__helvetica-neue, Helvetica, Arial, sans-serif;

$font-family__base: $font-family__helvetica-neue;

// Path
$font-path__helvetica-neue: "fonts/#{$font-name__helvetica-neue}/#{$font-name__helvetica-neue}";

// Weights
$font-weight__thin: 100;
$font-weight__extralight: 200;
$font-weight__light: 300;
$font-weight__regular: 400;
$font-weight__medium: 500;
$font-weight__semibold: 600;
$font-weight__bold: 700;
$font-weight__extrabold: 800;
$font-weight__heavy: 900;

// Styles
$font-style__base: normal;
$font-style__emphasis: italic;

// Size
$font-size__small: 12px;
$font-size__regular: 14px;
$font-size__normal: 16px;
$font-size__normal-plus: 18px;
$font-size__medium: 20px;
$font-size__medium-plus: 22px;
$font-size__big: 24px;
$font-size__extrabig: 28px;
$font-size__l: 32px;
$font-size__xl: 40px;
$font-size__xxl: 52px;
$font-size__3xl: 64px;
$font-size__4xl: 75px;