*, *::before, *::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

html, body {
    height: 100%;
    //overflow-x: hidden;
    max-width: 100vw;
}

img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
}

img {
    max-height: 100%;
}

input, button, textarea, select {
    font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
}

a {
    text-decoration: none;

    // TODO fix style load order
    //&, &:active, &:visited {
    //    color: inherit;
    //}
}

button {
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    overflow: visible;
    color: inherit;
}

input {
    border: none;

    &:focus {
        border: none;
        outline: none;
    }
}

li {
    list-style: none;
}