@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;

.item {
    display: flex;
    align-items: center;
    width: 100px;
    height: 100px;
    position: relative;
    border-radius: 50px;
    overflow: hidden;
    transition: width $short__duration, height $short__duration;
    cursor: pointer;
    transform: translateZ(0);

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, #ffffff00, #00000080);
    }
}

@media only screen and (min-width: $screen__m) {
    .item {
        display: block;
        width: 90%;
    }
}

@media only screen and (min-width: $screen__l) {
    .item {
        width: 60px;
        height: 380px;
    }
}

.itemActive {
    width: 400px;
    height: 350px;
}

@media only screen and (min-width: $screen__m) {
    .itemActive {
        .content {
            margin-bottom: 10px;
        }

        .arrowLink {
            bottom: 0;
        }
    }
}

@media only screen and (min-width: $screen__l) {
    .itemActive {
        width: 400px;
        height: 400px;
    }
}

@media only screen and (min-width: $screen__xl) {
    .itemActive {
        width: 400px;
    }
}

.image {
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    max-width: unset;
    max-height: unset;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

@media only screen and (min-width: $screen__m) {
    .image {
        width: 100%;
        height: unset;
    }
}

@media only screen and (min-width: $screen__l) {
    .image {
        width: unset;
        height: 100%;
        max-width: unset;
        max-height: 100%;
    }
}

.content {
    width: 100%;
    min-width: 280px;
    margin-left: 0;
    margin-top: 200px;
    transition: margin $short__duration;
    height: 75px;
    display: none;
    align-items: center;
}

.itemActive {
    .content {
        display: flex;
    }
}

@media only screen and (min-width: $screen__m) {
    .content {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 0 0 20px;
        width: calc(100% - 100px);
        margin-left: 100px;
        margin-bottom: 12.5px;
    }

    .itemActive {
        .content {
            margin-left: 0;
        }
    }
}

.desc {
    color: $color__primary-alt;
    min-width: 250px;
}

@media only screen and (min-width: $screen__m) {
    .desc {
        margin: 0 0 0 10px;
        text-align: left;
    }
}

.title {
    font-size: $font-size__medium;
    font-weight: $font-weight__medium;
}

.description {
    font-weight: $font-weight__light;
}

.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    transform: translate(-50%,-50%) rotate(10deg);
    opacity: 0;
    transition: opacity $short__duration;
}

.itemActive {
    .icon {
        opacity: 1;
        width: 90px;
    }
}

@media (max-width: 1050px) {
    .content {
        margin-top: 250px;
        margin-left: 20px;
        width: fit-content;
        z-index: 10;
    }
}

@media (max-width: 500px) {
    .itemActive {
        width: 90vw;
    }
}

