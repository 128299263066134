@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

@media only screen and (min-width: $screen__l) {
    .container {
        gap: 20px;
        flex-direction: row;
        justify-content: space-between;
    }
}

.content {
    position: relative;
}

@media only screen and (min-width: $screen__l) {
    .content {
        width: 40%;
    }
}

.contentImage {
    margin-bottom: 20px;
    max-width: min($screen__xs, 100%);
}

.title {
    font-size: $font-size__xl;
    margin-bottom: 20px;
}

.text {
    max-width: $screen__xxs;
}

.contentItem {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity $short__duration;
    z-index: -1;
}

.images {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    width: 100%;
}

@media only screen and (min-width: $screen__l) {
    .images {
        width: 50%;
    }
}

.imageWrapper {
    min-width: 12%;
    height: 200px;
    border-radius: 50px;
    overflow: hidden;
    transition: width $short__duration, min-width $short__duration, height $short__duration, box-shadow $short__duration, border-radius $short__duration;
    cursor: pointer;
    position: relative;
    transform: translateZ(0);

    &:hover {
        box-shadow: 0 0 10px 0 rgba($color__secondary-alt, 0.75);
    }

    &--active {
        min-width: 35%;
        border-radius: 150px;

        .image {
            filter: none;
        }
    }
}

.image {
    min-width: 100%;
    height: 100%;
    max-width: unset;
    max-height: unset;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: grayscale(1);
    transition: filter $short__duration;
    z-index: -1;
}

@media only screen and (min-width: $screen__s) {
    .imageWrapper {
        height: 400px;
    }
}

@media only screen and (min-width: $screen__l) {
    .imageWrapper {
        &:nth-child(2n - 1) {
            transform: translateY(-10%) translateZ(0);
        }

        &:nth-child(4n - 1) {
            transform: translateY(10%) translateZ(0);
        }
    }
}
