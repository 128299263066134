@use 'styles/variables/index' as *;
@use 'styles/variables/colors' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;
@use 'styles/mixins' as *;
@use 'styles/variables/colors' as *;
@use 'styles/variables/index' as *;
@use 'styles/variables/responsive' as *;
@use 'styles/variables/typography' as *;



.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 60px;
}

@media only screen and (min-width: $screen__l) {
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        margin-bottom: 120px;
    }
    
}



.header{
    font-size: 150px;
  background: linear-gradient(#b1fbb6, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@media only screen and (min-width: $screen__l) {
    .header{
        font-size: 220px;
    }
}

.title {
    line-height: 1.4;
    letter-spacing: 6px;
    text-transform: uppercase;
    position: relative;
    text-align: center;
    display: inline-block;
    margin-bottom: 20px;
    padding: 0 30px;
    text-align: center;
    font-size: $font-size__regular;

    &::before,
    &::after {
        content: "-";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: $font-size__medium;
        font-weight: bold;
        margin: 0px 4px;
    }

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }
}

@media only screen and (min-width: $screen__m) {
    .title {
        letter-spacing: 6px;
        text-transform: uppercase;
        position: relative;
        text-align: center;
        display: inline-block;
        margin-bottom: 0px;
        padding: 0 50px;
        text-align: center;
        font-size: $font-size__normal;
    
        &::before,
        &::after {
            content: "-";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: $font-size__medium;
            font-weight: bold;
            margin: 0px 4px;
        }
    
        &::before {
            left: 0;

        }
    
        &::after {
            right: 0;

        }
    }
}



.gohome{
 display: inline-block;
margin-top: 25px;
background: #b1fbb6;
color: #000;
font-weight: 500;
font-size: $font-size__regular;
padding: 10px 50px;
border-radius: 10px;
transition: color 300ms, background-color 300ms;
}




