.iconWrapper {
    overflow: hidden;
}

.icon {
    background-color: transparent;
    background-image: url('/assets/icons/icons.webp');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
}
