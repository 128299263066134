@use 'styles/variables/responsive' as *;

.section {
    margin: 0 auto;
    padding: 20px 0;
    width: 100%;
}

@media only screen and (min-width: $screen__m) {
    .section {
        padding: 60px 0;
    }
}
